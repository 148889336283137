var slackOchin = Object.freeze({
  "colors": {
    "activityBar.background": "#161F26",
    "activityBar.dropBackground": "#FFF",
    "activityBar.foreground": "#FFF",
    "activityBarBadge.background": "#8AE773",
    "activityBarBadge.foreground": "#FFF",
    "badge.background": "#8AE773",
    "breadcrumb.focusForeground": "#475663",
    "breadcrumb.foreground": "#161F26",
    "button.background": "#475663",
    "button.foreground": "#FFF",
    "button.hoverBackground": "#161F26",
    "debugExceptionWidget.background": "#AED4FB",
    "debugExceptionWidget.border": "#161F26",
    "debugToolBar.background": "#161F26",
    "dropdown.background": "#FFF",
    "dropdown.border": "#DCDEDF",
    "dropdown.foreground": "#DCDEDF",
    "dropdown.listBackground": "#FFF",
    "editor.background": "#FFF",
    "editor.findMatchBackground": "#AED4FB",
    "editor.foreground": "#000",
    "editor.lineHighlightBackground": "#EEEEEE",
    "editor.selectionBackground": "#AED4FB",
    "editor.wordHighlightBackground": "#AED4FB",
    "editor.wordHighlightStrongBackground": "#EEEEEE",
    "editorActiveLineNumber.foreground": "#475663",
    "editorGroup.emptyBackground": "#2D3E4C",
    "editorGroup.focusedEmptyBorder": "#2D3E4C",
    "editorGroupHeader.tabsBackground": "#2D3E4C",
    "editorHint.border": "#F9F9F9",
    "editorHint.foreground": "#F9F9F9",
    "editorIndentGuide.activeBackground": "#dbdbdb",
    "editorIndentGuide.background": "#F3F3F3",
    "editorLineNumber.foreground": "#b9b9b9",
    "editorMarkerNavigation.background": "#F9F9F9",
    "editorMarkerNavigationError.background": "#F44C5E",
    "editorMarkerNavigationInfo.background": "#6182b8",
    "editorMarkerNavigationWarning.background": "#F6B555",
    "editorPane.background": "#2D3E4C",
    "editorSuggestWidget.foreground": "#2D3E4C",
    "editorSuggestWidget.highlightForeground": "#2D3E4C",
    "editorSuggestWidget.selectedBackground": "#b9b9b9",
    "editorWidget.background": "#F9F9F9",
    "editorWidget.border": "#dbdbdb",
    "extensionButton.prominentBackground": "#475663",
    "extensionButton.prominentForeground": "#F6F6F6",
    "extensionButton.prominentHoverBackground": "#161F26",
    "focusBorder": "#161F26",
    "foreground": "#616161",
    "gitDecoration.addedResourceForeground": "#ECB22E",
    "gitDecoration.conflictingResourceForeground": "#FFF",
    "gitDecoration.deletedResourceForeground": "#FFF",
    "gitDecoration.ignoredResourceForeground": "#877583",
    "gitDecoration.modifiedResourceForeground": "#ECB22E",
    "gitDecoration.untrackedResourceForeground": "#ECB22E",
    "input.background": "#FFF",
    "input.border": "#161F26",
    "input.foreground": "#000",
    "input.placeholderForeground": "#a0a0a0",
    "inputOption.activeBorder": "#3E313C",
    "inputValidation.errorBackground": "#F44C5E",
    "inputValidation.errorForeground": "#FFF",
    "inputValidation.infoBackground": "#6182b8",
    "inputValidation.infoForeground": "#FFF",
    "inputValidation.warningBackground": "#F6B555",
    "inputValidation.warningForeground": "#000",
    "list.activeSelectionBackground": "#5899C5",
    "list.activeSelectionForeground": "#fff",
    "list.focusBackground": "#d5e1ea",
    "list.focusForeground": "#fff",
    "list.highlightForeground": "#2D3E4C",
    "list.hoverBackground": "#d5e1ea",
    "list.hoverForeground": "#fff",
    "list.inactiveFocusBackground": "#161F26",
    "list.inactiveSelectionBackground": "#5899C5",
    "list.inactiveSelectionForeground": "#fff",
    "list.invalidItemForeground": "#fff",
    "menu.background": "#161F26",
    "menu.foreground": "#F9FAFA",
    "menu.separatorBackground": "#F9FAFA",
    "notificationCenter.border": "#161F26",
    "notificationCenterHeader.foreground": "#FFF",
    "notificationLink.foreground": "#FFF",
    "notificationToast.border": "#161F26",
    "notifications.background": "#161F26",
    "notifications.border": "#161F26",
    "notifications.foreground": "#FFF",
    "panel.border": "#2D3E4C",
    "panelTitle.activeForeground": "#161F26",
    "progressBar.background": "#8AE773",
    "scrollbar.shadow": "#ffffff00",
    "scrollbarSlider.activeBackground": "#161F267e",
    "scrollbarSlider.background": "#161F267e",
    "scrollbarSlider.hoverBackground": "#161F267e",
    "settings.dropdownBorder": "#161F26",
    "settings.dropdownForeground": "#161F26",
    "settings.headerForeground": "#161F26",
    "sideBar.background": "#2D3E4C",
    "sideBar.foreground": "#DCDEDF",
    "sideBarSectionHeader.background": "#161F26",
    "sideBarSectionHeader.foreground": "#FFF",
    "sideBarTitle.foreground": "#FFF",
    "statusBar.background": "#5899C5",
    "statusBar.debuggingBackground": "#8AE773",
    "statusBar.foreground": "#FFF",
    "statusBar.noFolderBackground": "#161F26",
    "tab.activeBackground": "#FFF",
    "tab.activeForeground": "#000",
    "tab.border": "#F3F3F3",
    "tab.inactiveBackground": "#F3F3F3",
    "tab.inactiveForeground": "#686868",
    "terminal.ansiBlack": "#000000",
    "terminal.ansiBlue": "#6182b8",
    "terminal.ansiBrightBlack": "#90a4ae",
    "terminal.ansiBrightBlue": "#6182b8",
    "terminal.ansiBrightCyan": "#39adb5",
    "terminal.ansiBrightGreen": "#91b859",
    "terminal.ansiBrightMagenta": "#7c4dff",
    "terminal.ansiBrightRed": "#e53935",
    "terminal.ansiBrightWhite": "#ffffff",
    "terminal.ansiBrightYellow": "#ffb62c",
    "terminal.ansiCyan": "#39adb5",
    "terminal.ansiGreen": "#91b859",
    "terminal.ansiMagenta": "#7c4dff",
    "terminal.ansiRed": "#e53935",
    "terminal.ansiWhite": "#ffffff",
    "terminal.ansiYellow": "#ffb62c",
    "terminal.border": "#2D3E4C",
    "terminal.foreground": "#161F26",
    "terminal.selectionBackground": "#0006",
    "textPreformat.foreground": "#161F26",
    "titleBar.activeBackground": "#2D3E4C",
    "titleBar.activeForeground": "#FFF",
    "titleBar.border": "#2D3E4C",
    "titleBar.inactiveBackground": "#161F26",
    "titleBar.inactiveForeground": "#685C66",
    "welcomePage.buttonBackground": "#F3F3F3",
    "welcomePage.buttonHoverBackground": "#ECECEC",
    "widget.shadow": "#161F2694"
  },
  "displayName": "Slack Ochin",
  "name": "slack-ochin",
  "tokenColors": [
    {
      "settings": {
        "foreground": "#002339"
      }
    },
    {
      "scope": [
        "meta.paragraph.markdown",
        "string.other.link.description.title.markdown"
      ],
      "settings": {
        "foreground": "#110000"
      }
    },
    {
      "scope": [
        "entity.name.section.markdown",
        "punctuation.definition.heading.markdown"
      ],
      "settings": {
        "foreground": "#034c7c"
      }
    },
    {
      "scope": [
        "punctuation.definition.string.begin.markdown",
        "punctuation.definition.string.end.markdown",
        "markup.quote.markdown"
      ],
      "settings": {
        "foreground": "#00AC8F"
      }
    },
    {
      "scope": [
        "markup.quote.markdown"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#003494"
      }
    },
    {
      "scope": [
        "markup.bold.markdown",
        "punctuation.definition.bold.markdown"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#4e76b5"
      }
    },
    {
      "scope": [
        "markup.italic.markdown",
        "punctuation.definition.italic.markdown"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#C792EA"
      }
    },
    {
      "scope": [
        "markup.inline.raw.string.markdown",
        "markup.fenced_code.block.markdown"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#0460b1"
      }
    },
    {
      "scope": [
        "punctuation.definition.metadata.markdown"
      ],
      "settings": {
        "foreground": "#00AC8F"
      }
    },
    {
      "scope": [
        "markup.underline.link.image.markdown",
        "markup.underline.link.markdown"
      ],
      "settings": {
        "foreground": "#924205"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#357b42"
      }
    },
    {
      "scope": "string",
      "settings": {
        "foreground": "#a44185"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#174781"
      }
    },
    {
      "scope": "constant",
      "settings": {
        "foreground": "#174781"
      }
    },
    {
      "scope": "language.method",
      "settings": {
        "foreground": "#174781"
      }
    },
    {
      "scope": [
        "constant.character",
        "constant.other"
      ],
      "settings": {
        "foreground": "#174781"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "fontStyle": "",
        "foreground": "#2f86d2"
      }
    },
    {
      "scope": "variable.language.this",
      "settings": {
        "fontStyle": "",
        "foreground": "#000000"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "fontStyle": "",
        "foreground": "#7b30d0"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "fontStyle": "",
        "foreground": "#da5221"
      }
    },
    {
      "scope": "storage.type",
      "settings": {
        "fontStyle": "",
        "foreground": "#0991b6"
      }
    },
    {
      "scope": "entity.name.class",
      "settings": {
        "foreground": "#1172c7"
      }
    },
    {
      "scope": "entity.other.inherited-class",
      "settings": {
        "fontStyle": "",
        "foreground": "#b02767"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "fontStyle": "",
        "foreground": "#7eb233"
      }
    },
    {
      "scope": "variable.parameter",
      "settings": {
        "fontStyle": "",
        "foreground": "#b1108e"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "fontStyle": "",
        "foreground": "#0444ac"
      }
    },
    {
      "scope": "text.html.basic",
      "settings": {
        "fontStyle": "",
        "foreground": "#0071ce"
      }
    },
    {
      "scope": "entity.name.type",
      "settings": {
        "foreground": "#0444ac"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#df8618"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "fontStyle": "",
        "foreground": "#1ab394"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "fontStyle": "",
        "foreground": "#174781"
      }
    },
    {
      "scope": [
        "support.type",
        "support.class"
      ],
      "settings": {
        "foreground": "#dc3eb7"
      }
    },
    {
      "scope": "support.other.variable",
      "settings": {
        "foreground": "#224555"
      }
    },
    {
      "scope": "invalid",
      "settings": {
        "fontStyle": " italic bold underline",
        "foreground": "#207bb8"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "fontStyle": " bold italic underline",
        "foreground": "#207bb8"
      }
    },
    {
      "scope": "source.json support",
      "settings": {
        "foreground": "#6dbdfa"
      }
    },
    {
      "scope": [
        "source.json string",
        "source.json punctuation.definition.string"
      ],
      "settings": {
        "foreground": "#00820f"
      }
    },
    {
      "scope": "markup.list",
      "settings": {
        "foreground": "#207bb8"
      }
    },
    {
      "scope": [
        "markup.heading punctuation.definition.heading",
        "entity.name.section"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#4FB4D8"
      }
    },
    {
      "scope": [
        "text.html.markdown meta.paragraph meta.link.inline",
        "text.html.markdown meta.paragraph meta.link.inline punctuation.definition.string.begin.markdown",
        "text.html.markdown meta.paragraph meta.link.inline punctuation.definition.string.end.markdown"
      ],
      "settings": {
        "foreground": "#87429A"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#87429A"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#08134A"
      }
    },
    {
      "scope": [
        "markup.italic",
        "punctuation.definition.italic"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#174781"
      }
    },
    {
      "scope": "meta.link",
      "settings": {
        "foreground": "#87429A"
      }
    }
  ],
  "type": "light"
});

export { slackOchin as default };
